.testimonials {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 2rem;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: flex-end;
  width: 100%;
}

.wrapper > img {
  width: 25rem;
  justify-self: center;
}

.wrapper > :nth-child(3) {
  text-align: right;
}

.container {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.container > :nth-child(1) {
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
}

.container > :nth-child(2) {
  display: block;
  font-size: 0.8rem;
  text-transform: uppercase;
}

.reviews {
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
}

.carousel {
  width: 100%;
}

.myCarousel {
  padding: 2rem !important;
}

.testimonial {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 1rem;
  background: #ffff;
  border-radius: 0.5rem;
  box-shadow: 0 1rem 3rem -50px #7d7d7d;
}

.testimonial>img {
  position: absolute;
  top: -1.5rem;
  left: 45%;
  width: 3rem;
}

.testimonial>span:nth-of-type(1) {
  font-size: 0.8rem;
  letter-spacing: 1px;
  margin-top: 2rem;
  text-align: center;
}

.testimonial>hr {
  height: 1px;
  width: 80%;
  background: #c6c6c6;
  border: none;
}
@media screen and (max-width: 640px) {
  .wrapper {
    grid-template-columns: 1fr;
  }

  .wrapper>img {
    width: 80vw;
  }

  .wrapper>:nth-child(3),
  .wrapper>:nth-child(1) {
    text-align: center;
  }
}