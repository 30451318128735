.container {
  padding: 0 2rem;
  position: relative;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container>:nth-child(1) {
  position: absolute;
  width: 8rem;
  left: 30%;
  top: -3rem;
}

.products {
  /* display: grid;
  grid-template-columns: 25% auto; */
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.menu {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  font-weight: 500;
  font-size: 1.3rem;
  list-style: none;
  padding: 0;
  flex-basis: 20%;
}

.menu>li:hover {
  color: var(--pink);
  cursor: pointer;
}

.productList {
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 2rem; */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  flex-basis: 80%;
  height: 22rem;
  overflow-y: scroll;
}

.product {
  width: 12rem;
  height: 8rem;
  background-color: #ffff;
  position: relative;
  overflow: hidden;
  padding: 1rem;
  display: flex;
  border-radius: 1rem;
}

.product>img {
  top: 3rem;
  width: 6rem;
  height: 11rem;
}

@media (max-width: 856px) {
  .container {
    gap: 1rem;
  }
  .container>:nth-child(1) {
    display: none;;
  }
  .container>:nth-child(2) {
    text-align: center;
  }
  .products {
    /* grid-template-columns: none;
    gap: 1rem; */
    flex-direction: column;
  }
  .menu {
    /* flex-direction: row; */
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
  }
  .productList {
    justify-content: center;
    max-height: 50vh;
  }
}