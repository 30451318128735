.footerWrapper {
  width: 100%;
  margin-top: 5rem;
}

.footerWrapper>hr {
  background: #fff;
  border: none;
  height: 1px;
  margin-bottom: 1rem;
  width: 100%;
}

.footer {
  display: flex;
  width: 100%;
  justify-content: space-around
}

.logo {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.logo>img {
  width: 2.5rem;
  height: 2.5rem;
}

.block {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 12rem;
}

.detail {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: inherit;
  font-style: italic;
  font-size: 0.7rem;
  gap: 1rem;
}

.detail>span:nth-of-type(1) {
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
}

.containLine:hover {
  cursor: pointer;
}

.containLine {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.icon {
  width: 25px;
}

.copyRight {
  display: flex;
  flex-direction: column;
  font-size: 0.65rem;
  margin-top: 2rem;
  text-align: center;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .footer {
    flex-wrap: wrap;
    gap: 2rem;
    align-items: flex-start;
    justify-content: center;
    font-size: 1.7rem;
  }
  .logo>span {
    font-size: 1.2rem;
  }
}